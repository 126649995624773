<template>
    <div :class="wrapperClass">
        <label v-if="label">{{label}}</label>
        <InputNumber 
        v-if="mode == 'currency'"
        :mode="mode" 
        :prefix="prefix"
        currency="USD" 
        :readonly="readonly"
        :disabled="disabled"
        locale="en-US"
        :min="min"
        :max="max" 
        :class="{ 'p-invalid': valid === false }"
        v-model="localValue"
        @keydown.tab.exact="$emit('tab')"
        @keydown.shift.tab.exact="$emit('shift-tab')"
        @keydown.enter.exact="$emit('enter')"
        :showButtons="showStepper"
        :buttonLayout="buttonLayout"
        :step="step"
        decrementButtonClass="p-button-primary" 
        incrementButtonClass="p-button-primary" 
        incrementButtonIcon="pi pi-plus" 
        decrementButtonIcon="pi pi-minus"
        @input="$emit('update:modelValue', $event.value)" />
        <InputNumber 
        :prefix="prefix"
        :readonly="readonly"
        :disabled="disabled"
        locale="en-US"
        currency="USD" 
        v-else :class="{ 'p-invalid': valid === false }"
        v-model="localValue"
        :min="min"
        :max="max"
        :minFractionDigits="minFractionDigits" 
        :maxFractionDigits="maxFractionDigits"
        :showButtons="showStepper"
        :buttonLayout="buttonLayout"
        :step="step"
        decrementButtonClass="p-button-primary" 
        incrementButtonClass="p-button-primary" 
        incrementButtonIcon="pi pi-plus" 
        decrementButtonIcon="pi pi-minus"
        @keydown.tab.exact="$emit('tab')"
        @keydown.shift.tab.exact="$emit('shift-tab')"
        @keydown.enter.exact="$emit('enter')"
        @input="$emit('update:modelValue', $event.value)" />
        <small class="p-invalid" v-if="valid === false"> 
            {{validation}}
        </small>
    </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1
    },
    showStepper: {
      type: Boolean,
      default: null
    },
    modelValue: {
      type: [String, Number, null],
      required: true,
    },
    buttonLayout: {
      type: String,
      default: 'horizontal'
    },
    prefix: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: "decimal"
    },
    wrapperClass: {
        type: String,
        default: "field"
    },
    label: {
        type: String,
        default: null
    },
    minFractionDigits: {
      type: Number,
      default: null,
    },
    maxFractionDigits: {
      type: Number,
      default: null,
    },
    valid: {
        type: Boolean,
        //Todos son validos antes del submit
        default: true
    },
    disabled: {
        type: Boolean,
        default: false
    },
    validation: {
        type: String,
        default: "Favor de llenar el campo"
    },
    textArea: {
        type: Boolean,
        default: false
    },
    min: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      localValue: this.modelValue,
    };
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    modelValue(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>

<style>

</style>